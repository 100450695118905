.App {
  text-align: center;
}

.logo {
  color: #61cdff;
  background-image: -webkit-linear-gradient(0deg, #61cdff 20%, #ed91ff 74%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.identicon {
  height: 60px !important;
  width: 60px !important;
  border-radius: 360px !important;
  margin-bottom: 10px !important;
}
.bf {
  color: #465df5;
}

body {
  background-color: #111111 !important;
}
.bold {
  font-weight: bold !important;
}

.icon {
  display: flex;
  justify-content: flex-end;
}

.i {
  width: 40px;
  height: 40px;
  border: 1px solid #282c34;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
}
.i:hover {
  background-color: #282c34;
  color: white;
}
.Footer {
  padding: 25px;
  color: rgb(126, 126, 126);
  width: 100%;
  margin-top: 50px;
}
.css-levciy-MuiTablePagination-displayedRows {
  margin-top: 20px !important;
}
.css-pdct74-MuiTablePagination-selectLabel {
  margin-top: 20px !important;
}
.css-5wue95-MuiInputBase-root-MuiTablePagination-select {
  margin-top: 10px !important;
}
input {
  padding: 11px !important;
  background-color: #0a0b0d !important;
  color: white !important;
  border: 1px solid #636363 !important;
}
input::placeholder {
  color: gray !important;
}

input:focus {
  background-color: #0a0b0d !important;
  color: white !important;
}

.App-link {
  text-decoration: none;
}
.top {
  background-color: #000000;
}
.cardx {
  background: rgb(63, 94, 251);
  background: linear-gradient(
    90deg,
    rgba(63, 94, 251, 1) 10%,
    rgba(252, 70, 107, 1) 100%
  );
  border-radius: 5px;
  color: white;
}

.cardz {
  background: rgb(255, 128, 184);
  background: linear-gradient(
    90deg,
    rgba(255, 128, 184, 1) 0%,
    rgba(158, 200, 249, 1) 100%
  );
  border-radius: 5px;
  color: rgb(255, 255, 255);
}

.cardy {
  background: rgb(198, 91, 255);
  background: linear-gradient(
    72deg,
    rgba(198, 91, 255, 1) 0%,
    rgba(255, 249, 113, 1) 100%
  );
  border-radius: 5px;
  color: rgb(255, 255, 255);
}

.card {
  background-color: #000000 !important;
  border: none !important;
  border-radius: 5px !important;
  color: rgb(255, 255, 255) !important;
}

.cardb {
  background: radial-gradient(
        circle at 100% 100%,
        #111111 0,
        #111111 5px,
        transparent 5px
      )
      0% 0%/8px 8px no-repeat,
    radial-gradient(circle at 0 100%, #111111 0, #111111 5px, transparent 5px)
      100% 0%/8px 8px no-repeat,
    radial-gradient(circle at 100% 0, #111111 0, #111111 5px, transparent 5px)
      0% 100%/8px 8px no-repeat,
    radial-gradient(circle at 0 0, #111111 0, #111111 5px, transparent 5px) 100%
      100%/8px 8px no-repeat,
    linear-gradient(#111111, #111111) 50% 50% / calc(100% - 6px)
      calc(100% - 16px) no-repeat,
    linear-gradient(#111111, #111111) 50% 50% / calc(100% - 16px)
      calc(100% - 6px) no-repeat,
    linear-gradient(122deg, #e600ff 0%, #39d9ff 99%);
  border-radius: 8px;
  padding: 7px;
  box-sizing: border-box;
}

.card p {
  color: rgb(201, 201, 201);
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.moon {
  border: 1px solid rgba(49, 49, 49, 0.986) !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 35px 35px !important;
}
.moon .p {
  color: #8a8a8a;
}
.bg-darkX {
  background-color: #0a0b0d00 !important;
}
.font-weight-bold {
  font-weight: bold;
}
.moon input {
  padding: 11px !important;
  background-color: #0a0b0d;
  color: white;
  border: 1px solid #636363;
}
.moon input::placeholder {
  color: gray;
}

.moon input:focus {
  background-color: #0a0b0d;
  color: white;
}

.text-primaryX {
  background: #485df4;
  background: linear-gradient(to right, #485df4 0%, #d74b88 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

canvas {
  width: 60%;
  height: 200px !important;
}

.nav-tabs .nav-item button {
  color: #c2c2c2 !important;
}

.nav-tabs .nav-item .active {
  background: rgb(63, 94, 251);
  background: linear-gradient(
    90deg,
    rgba(63, 94, 251, 1) 10%,
    rgba(252, 70, 107, 1) 100%
  );
  border-bottom: 3px solid rgba(252, 70, 107, 1) 100%;
  color: white;
  font-weight: bold;
  border: none;
}

.nav-tabs {
  border-bottom: 2px solid #181818 !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 767px) {
  .btn {
    width: 100%;
  }
}
